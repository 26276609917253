import _nuxt_components_plugin_mjs_KR1HBZs4kY from "/home/runner/work/manamiru-front/manamiru-front/.nuxt/components.plugin.mjs";
import node_modules_nuxt_dist_head_runtime_lib_vueuse_head_plugin_mjs_D7WGfuP1A0 from "/home/runner/work/manamiru-front/manamiru-front/node_modules/nuxt/dist/head/runtime/lib/vueuse-head.plugin.mjs";
import node_modules_nuxt_dist_pages_runtime_plugins_router_mjs_Pg0DINazwm from "/home/runner/work/manamiru-front/manamiru-front/node_modules/nuxt/dist/pages/runtime/plugins/router.mjs";
import node_modules_nuxt_dist_pages_runtime_plugins_prefetch_client_mjs_3cwHvxIDEX from "/home/runner/work/manamiru-front/manamiru-front/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.mjs";
import node_modules_nuxt_dist_app_plugins_payload_client_mjs_5Om5dvb8Jc from "/home/runner/work/manamiru-front/manamiru-front/node_modules/nuxt/dist/app/plugins/payload.client.mjs";
import src_plugins_gtm_index_ts_fjKRXbnAqk from "/home/runner/work/manamiru-front/manamiru-front/src/plugins/gtm/index.ts";
import src_plugins_sentry_client_ts_KAXFuL2wum from "/home/runner/work/manamiru-front/manamiru-front/src/plugins/sentry.client.ts";
export default [
  _nuxt_components_plugin_mjs_KR1HBZs4kY,
  node_modules_nuxt_dist_head_runtime_lib_vueuse_head_plugin_mjs_D7WGfuP1A0,
  node_modules_nuxt_dist_pages_runtime_plugins_router_mjs_Pg0DINazwm,
  node_modules_nuxt_dist_pages_runtime_plugins_prefetch_client_mjs_3cwHvxIDEX,
  node_modules_nuxt_dist_app_plugins_payload_client_mjs_5Om5dvb8Jc,
  src_plugins_gtm_index_ts_fjKRXbnAqk,
  src_plugins_sentry_client_ts_KAXFuL2wum
]